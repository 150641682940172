import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import style from './HomePage.module.scss';
import SimpleSlider from '../../components/Modules/Slider/Slider';
import WhyUsSection from '../../components/Modules/WhyUsSection';
import History from '../../components/Modules/History';
import Catalog from '../../components/Modules/Catalog';
import PhotoSlider from 'src/components/Modules/PhotoSlider';
import HolidayVideoBlog from 'src/components/Modules/HolidayVideoBlog';
import CallToAction from 'src/components/Modules/CallToAction';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ExternalLinkBlock from 'src/components/Elements/ExternalLinkBlock';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.home')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций». Один из крупнейших производителей металлоконструкций для стройиндустрии в СНГ, успешно функционирующий более 50 лет. Высококлассные специалисты и мощные производственные мощности позволяют нам выполнять работы любой сложности."
        />
        <link rel="canonical" href="https://mzmk.by" />
      </Helmet>
      <SimpleSlider />
      <div className={style.homePage}>
        <ExternalLinkBlock />
        <PhotoSlider />
        <Catalog />
        <WhyUsSection />
        <History />
        {/* ко Дню народного единства 17 сентября */}
        {/* <HolidayVideoBlog /> */}
      </div>
    </PageTemplate>
  );
};

export default HomePage;
