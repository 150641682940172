import React from 'react';
import style from './ExternalLinkBlock.module.scss';
import image_1 from 'src/assets/images/Presidential_elections_26.01.25.png';

const ExternalLinkBlock = () => {
  return (
    <div className={style.externalLinkBlock}>
      <a
        href="https://www.molodechno.gov.by/vybory-prezidenta-respubliki-belarus-2025"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={image_1}
          alt="Молодечненский Исполком"
          className={style.linkImage}
        />
      </a>
    </div>
  );
};

export default ExternalLinkBlock;
